import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../../models/user-profile.model';
import { UtilService } from '../../../service/util.service';
import { ReportService } from '../report.service';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { NavigationExtras, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { ReportRekapPromoComponent } from '../../../component/advanced-filter/report-rekap-promo/report-rekap-promo.component';

import { AES } from 'crypto-js';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-rekap-promo',
  templateUrl: './rekap-promo.page.html',
  styleUrls: ['./rekap-promo.page.scss'],
})
export class RekapPromoPage implements OnInit {
  token: any;
  userProfile: UserProfile = new UserProfile();
  userAccess: any[] = [];
  reportData:any[]=[];
  branchName:string='';
  counterName:string='';
  dateStart:any='';
  dateEnd:any='';
  branchData: any[] = [];
  counterData: any[] = [];
  roleAccess = new RoleAccess();
  fileName= 'Rekap_Promo.xlsx';
  showBtnCounterList: boolean = false;
  showBtnBranchList: boolean = false;
  counterListForShow: any[] = [];
  branchListForShow:any[] =[];
  idBranch: any= 'null';
  idCounter: any = 'null';
  paramBranchId: any;
  paramCounterId: any;
  paramPeriodStart: any;
  paramPeriodEnd: any;
  paramStatus:any;
  status:number=2;
  statusName:string='Semua';
  listStatus: any[] = ['Not Active','Active',  'All'];
  constructor(
    private storage: Storage,
    private utilService:UtilService,
    private reportService: ReportService,
    private toastCtrl:ToastController,
    private router: Router,
    private userDataProvider: UserData,
    private modalController:ModalController,
    private navCtrl:NavController
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userAccess = access;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": counterId
          };
          this.reportService.getReportCashierIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.branchData = response.results.branch_data;
            this.counterData = response.results.counter_data;
            this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
            let branchId='';
            if(this.branchData.length>0){
              for(let i = 0; i < this.branchData.length; i++) {
                if(branchId === '') {
                  branchId = this.branchData[i].id;
                } else {
                  branchId = branchId + ', ' + this.branchData[i].id;
                }
              }
            }
            this.generateReport(branchId,counterId, new Date(), new Date(),this.status);
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        });
      }
    });
  }
  generateReport(branchId:any,counterId: any, periodStart: any, periodEnd: any,status:number) {
    let startDate = new Date(periodStart);
    let endDate = new Date(periodEnd);
    let convertDateStart = this.utilService.convertDate(startDate);
    let convertDateEnd = this.utilService.convertDate(endDate);
    this.dateStart = convertDateStart.years + '-' + convertDateStart.months + '-' + convertDateStart.dates;
    this.dateEnd = convertDateEnd.years + '-' + convertDateEnd.months + '-' + convertDateEnd.dates;
    this.idBranch = branchId !== 'undefined' && branchId !== '' ? branchId : 'null';
    this.idCounter = counterId !== 'undefined' && counterId !== '' ? counterId : 'null';

    this.paramPeriodStart = periodStart;
    this.paramPeriodEnd = periodEnd;
    this.paramBranchId = branchId;
    this.paramCounterId = counterId;
    this.paramStatus=status;
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "start_date": this.dateStart,
        "end_date": this.dateEnd,
        "branch_id":this.idBranch,
        "counter_id": this.idCounter,
        "status":status
      };

      this.reportService.getReportRekapPromo(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.counterListForShow = response.counter_data;
          this.branchListForShow = response.branch_data;
          this.reportData = response.results;
          this.branchData =this.branchListForShow;
          this.statusName=this.listStatus[status];
          if(this.counterListForShow.length > 0) {
            let loopCounter = 0;
            if(this.counterListForShow.length > 5) {
              loopCounter = 5;
              this.showBtnCounterList = true;
            } else {
              loopCounter = this.counterListForShow.length;
              this.showBtnCounterList = false;
            }

            this.counterName = '';
            for(let i = 0; i < loopCounter; i++) {
              if(this.counterName === '') {
                this.counterName = this.counterListForShow[i].counter_name;
              } else {
                this.counterName = this.counterName + ', ' + this.counterListForShow[i].counter_name;
              }
            }
          } else {
            this.counterName = 'Data Not Found';
          }
          
          if(this.branchListForShow.length > 0) {
            let loopBranch = 0;
            if(this.branchListForShow.length >5) {
              loopBranch = 5;
              this.showBtnBranchList = true;
            } else {
              loopBranch = this.branchListForShow.length;
              this.showBtnBranchList = false;
            }

            this.branchName = '';
            for(let i = 0; i < loopBranch; i++) {
              if(this.branchName === '') {
                this.branchName = this.branchListForShow[i].branch_name;
              } else {
                this.branchName = this.branchName + ', ' + this.branchListForShow[i].branch_name;
              }
            }
          } else {
            this.showBtnBranchList = false;
            this.branchName = 'Data Not Found';
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }


  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ReportRekapPromoComponent,
      componentProps: {
        branchList: this.branchData,
        counterList: this.counterData,
        paramBranchId: this.paramBranchId,
        paramCounterId: this.paramCounterId,
        paramPeriodStart: this.paramPeriodStart,
        paramPeriodEnd: this.paramPeriodEnd,
        paramStatus : this.paramStatus
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
console.log(data);
      if (data !== 'null') {
        let splitData = data.split('#');
        this.generateReport(splitData[0],splitData[1], splitData[2], splitData[3],splitData[4]);
      }
    });

    return await modal.present();
  }

  goToDetail(id: any) {
    id=parseInt(id);
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/promotions/promotion-detail'], navigationExtras);
  }
}
