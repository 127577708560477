import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { OtherExpencesService } from '../other-expences.service';
import { OtherExpencesStatus } from '../../../enum/OtherExpencesStatus';
import { RoleAccess } from '../../../models/role-access.model';
import { OtherExpencesClosedBundle } from '../../../models/other-expences-cl-bundle.model';
import { FindProductComponent } from '../../../component/find-product/find-product.component';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';
import { PosService } from '../../pos/pos.service';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-other-expences-edit',
  templateUrl: './other-expences-edit.page.html',
  styleUrls: ['./other-expences-edit.page.scss'],
})
export class OtherExpencesEditPage implements OnInit {

  token: any;
  productSearch: Product[];
  productList: Product[];
  userProfile: UserProfile = new UserProfile();
  counterData: any[] = [];
  warehouseData: any[] = [];
  purposeData: any[] = [];
  otherExpencesData: any;
  otherExpencesDetailData: any[] = [];
  statusOtherExpences: any = 0;
  otherExpencesDetailList: any[] = [];

  formOtherExpencesEdit: FormGroup;
  otherExpencesId: any;

  expencesStatus = OtherExpencesStatus;
  expencesStatusList = Object.keys(OtherExpencesStatus).filter(
    expencesStatus => typeof this.expencesStatus[expencesStatus] === 'number'
  );

  approvalHistoryData: any[] = [];
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;

  userAccess: any[] = [];
  roleAccess = new RoleAccess();
  roleName: any;

  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];
  
  warehouseId :any='';
  counterId :any='';
  selectedWarehouses:any[];
  warehouseListShow:any[]=[];
  
  stock:Array<any>;
  todayDate:any='';

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private activeRoute: ActivatedRoute,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private expencesService: OtherExpencesService,
    private router: Router,
    private modalController: ModalController,
    private userData: UserData,
    private posService:PosService
  ) { }

  ngOnInit() {
    this.buildFormOtherExpences();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.userAccess = access;
          this.roleName = this.userProfile.role_detail ? this.userProfile.role_detail.role_name : null;
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getAllProduct() {
    this.expencesService.getProduct({ "token": this.token }).subscribe((response) => {
      if (response.status.code === 200) {
        this.productList = response.results;
      }
    }, () => {
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  buildFormOtherExpences() {
    let newDate = new Date();
    let convertDate = this.utilService.convertDate(newDate);
    this.todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formOtherExpencesEdit = this.fb.group({
      otherExpencesId: [this.otherExpencesId],
      counterId: [null],
      warehouseId: [null],
      purposeId: [null],
      docDate: [this.todayDate],
      description: [null],
      status: [null]
    });
  }

  filterProducts(products: Product[], text: string) {
    return products.filter(product => {
      return product.product_name.toLowerCase().indexOf(text) !== -1 ||
        product.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.otherExpencesId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.otherExpencesId = this.otherExpencesId.replaceAll('"','');
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let options = {
          "token": this.token,
          "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
        };
        this.expencesService.getOtherExpencesforEdit(this.otherExpencesId, options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.otherExpencesData = response.results.other_expences_data;
          this.otherExpencesDetailData = response.results.other_expences_detail_data;
          this.productList = response.results.product_data;
          this.counterData = response.results.counter_data;
          this.warehouseData = response.results.warehouse_data;
          this.warehouseListShow = this.warehouseData;
          this.purposeData = response.results.purpose_data;

          let userCreate: any = response.results.user_create_data;
          this.createdBy = userCreate ? userCreate[0].name : null;
          let userUpdate: any = response.results.user_update_data;
          this.updatedBy = userUpdate ? userUpdate[0].name : null;
          this.approvalHistoryData = response.results.approval_history_data;
          
          if(this.otherExpencesData.length > 0) {
            this.createdAt = this.otherExpencesData[0].created_at;
            this.updatedAt = this.otherExpencesData[0].updated_at;
            this.statusOtherExpences = this.otherExpencesData[0].status;
            let statusName = this.expencesStatusList[parseInt(this.statusOtherExpences)];
            this.warehouseId = this.otherExpencesData[0].warehouse_id;

            let selectedWarehouse: any = this.warehouseData.find(x => x.id ==  this.warehouseId);
            if(selectedWarehouse){
              let idx =this.warehouseData.indexOf(selectedWarehouse);
              this.selectedWarehouses=(this.warehouseData[idx]);
            }
            this.counterId=this.otherExpencesData[0].counter_id;
            let filterWarehouse = this.warehouseData.filter(x => parseInt(x.counter_id) === parseInt(this.counterId));
            this.warehouseListShow = filterWarehouse;  
            this.formOtherExpencesEdit = this.fb.group({
              otherExpencesId: [this.otherExpencesId],
              counterId: [this.otherExpencesData[0].counter_id],
              warehouseId: [this.otherExpencesData[0].warehouse_id],
              purposeId: [this.otherExpencesData[0].purpose_id],
              docDate: [this.otherExpencesData[0].doc_date],
              description: [this.otherExpencesData[0].desc],
              status: [statusName]
            });
          }

          if(this.otherExpencesDetailData.length > 0) {
            this.otherExpencesDetailList = [];
            for(let i = 0; i < this.otherExpencesDetailData.length; i++) {
              this.otherExpencesDetailList.push({
                'id' : i + 1,
                'product_id' : this.otherExpencesDetailData[i].product_id,
                'product_name' : this.otherExpencesDetailData[i].product_name,
                'qty_receive': this.otherExpencesDetailData[i].qty,
                'stock': this.otherExpencesDetailData[i].stock,
                'desc': this.otherExpencesDetailData[i].description
              });
            }
          } else {
            this.otherExpencesDetailList = [];
          }
          if(this.statusOtherExpences==0){
            this.getStock();
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateBundle(action: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formOtherExpences = this.formOtherExpencesEdit.value;
      let docDateConvert = this.utilService.convertDate(formOtherExpences.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      const otherExpencesClosedBundle = new OtherExpencesClosedBundle();
      otherExpencesClosedBundle.otherExpences.id = this.otherExpencesId;
      otherExpencesClosedBundle.otherExpences.warehouse_id = this.warehouseId;
      otherExpencesClosedBundle.otherExpences.purpose_id = formOtherExpences.purposeId;
      otherExpencesClosedBundle.otherExpences.doc_date = documentDate;
      otherExpencesClosedBundle.otherExpences.desc = formOtherExpences.description;
      otherExpencesClosedBundle.otherExpences.status = action === 'data' ? '0' : '1';
      otherExpencesClosedBundle.otherExpences.updated_by = this.userProfile.username;

      let arrProduct: any = [];
      let arrQty: any = [];
      let arrStock: any = [];
      let arrUnitType: any = [];
      let arrDesc: any = [];

      for(let x = 0; x < this.otherExpencesDetailList.length; x++) {
        let htmlIdQtyReceive: any = 'qtyReceive_' + x;
        let htmlIdDesc: any = 'description_' + x;
        let qtyReceive: any = (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;
        let description: any = (<HTMLInputElement>document.getElementById(htmlIdDesc)).value;

        arrProduct[x] = this.otherExpencesDetailList[x].product_id;
        arrStock[x] = this.otherExpencesDetailList[x].stock;
        arrQty[x] = parseInt(qtyReceive);
        arrUnitType[x] = 'PCS';
        arrDesc[x] = description;
      }

      otherExpencesClosedBundle.otherExpencesDetail.product_id = arrProduct;
      otherExpencesClosedBundle.otherExpencesDetail.qty = arrQty;
      otherExpencesClosedBundle.otherExpencesDetail.stock = arrStock;
      otherExpencesClosedBundle.otherExpencesDetail.unit = arrUnitType;
      otherExpencesClosedBundle.otherExpencesDetail.description = arrDesc;        
      let message = '';
      if(action === 'status') {
        //cek stok dulu sebelum close
        if(this.otherExpencesDetailList.length >0){
          for(let i=0;i<this.otherExpencesDetailList.length;i++){
            let checkStock:any = this.stock.find(x => x.product_id === this.otherExpencesDetailList[i].product_id);
            let stock:number=0;
            if (checkStock) {
              stock = checkStock.stock;
            }
            this.otherExpencesDetailList[i].stock = stock;
            let htmlIdQtyReceive: any = 'qtyReceive_' + i;
            let qtyReceive=(<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;
            if(parseInt(qtyReceive)>stock){
              message += this.otherExpencesDetailList[i].product_id+',';
              (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value='0';
            }
          }
        }
        if(message!=''){
          this.utilService.loadingDismiss();
          this.showAlert('Pengeluaran product '+message+' tidak boleh melebihi stok');
        }
        else{
          let timezoneName = this.userProfile.counter_detail && this.userProfile.counter_detail.timezone !== undefined ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
          let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
          let convertDate = this.utilService.convertDate(new Date());
          let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
          let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

          otherExpencesClosedBundle.approvalHistory.transaction_id = this.otherExpencesId;
          otherExpencesClosedBundle.approvalHistory.username = this.userProfile.username;
          otherExpencesClosedBundle.approvalHistory.status = '1';
          otherExpencesClosedBundle.approvalHistory.trans_date = transDate + ' ' + transTime;

          let arrWarehouseId: any[] = [];
          let arrTransactionId: any[] = [];
          let arrStockMutationTypeId: any[] = [];
          let arrProductId: any[] = [];
          let arrQty: any[] = [];
          let arrValue: any[] = [];
          let arrStockMove: any[] = [];
          let arrTransDate: any[] = [];
          let arrYear: any[] = [];
          let arrMonth: any[] = [];

          if(this.otherExpencesDetailList.length > 0) {
            for(let x = 0; x < this.otherExpencesDetailList.length; x++) {
              let htmlIdQtyReceive: any = 'qtyReceive_' + x;
              let qtyReceive: any = (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;

              arrYear[x] = convertDate.years;
              arrMonth[x] = convertDate.months;
              arrWarehouseId[x] = this.warehouseId;
              arrTransactionId[x] = this.otherExpencesId;
              arrStockMutationTypeId[x] = 'PL'; // DL = PENERIMAAN LAIN
              arrStockMove[x] = 'O';
              arrProductId[x] = this.otherExpencesDetailList[x].product_id;
              arrQty[x] = parseInt(qtyReceive);
              arrValue[x] = 0;
              arrTransDate[x] = transDate;
            }

            otherExpencesClosedBundle.stockMutation.warehouse_id = arrWarehouseId;
            otherExpencesClosedBundle.stockMutation.transaction_id = arrTransactionId;
            otherExpencesClosedBundle.stockMutation.stock_mutation_type_id = arrStockMutationTypeId;
            otherExpencesClosedBundle.stockMutation.product_id = arrProductId;
            otherExpencesClosedBundle.stockMutation.qty = arrQty;
            otherExpencesClosedBundle.stockMutation.value = arrValue;
            otherExpencesClosedBundle.stockMutation.stock_move = arrStockMove;
            otherExpencesClosedBundle.stockMutation.trans_date = arrTransDate;
          }
        }
      }
      if(message==''){
        this.expencesService.updateOtherExpencesBundle(this.otherExpencesId, otherExpencesClosedBundle)
        .subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.showConfirmUpdate();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      }
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/other-expences']);;
          }
        }
      ]
    });

    await alert.present();
  }

  addDetail() {
    let length = this.otherExpencesDetailList.length;
    this.otherExpencesDetailList.push({
      'id' : length + 1,
      'product_id' : null,
      'product_name' : null,
      'unit': null
    });
  }

  async findProduct(index: any) {
    const modal = await this.modalController.create({
      component: FindProductComponent,
      componentProps: {
        'productList': this.productList
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if(data) {
        let check = this.otherExpencesDetailList.find(x=>x.product_id==data.id);
        if(check){
          this.showAlert('produk '+data.id+' sudah ada di list');
        }
        else{
          let findProduct = this.otherExpencesDetailList.indexOf(data);
          if(findProduct === -1) {
            this.otherExpencesDetailList[index].product_id = data.id;
            this.otherExpencesDetailList[index].product_name = data.product_name;
            let checkStock:any = this.stock.find(x => x.product_id === data.id);
            let stock:number=0;
            if (checkStock) {
              stock = checkStock.stock;
            }
            this.otherExpencesDetailList[index].stock = stock;
          }
        }
      }
    });

    return await modal.present();
  }

  deleteProduct(index: any) {
    this.otherExpencesDetailList.splice(index, 1);
  }
  
  selectWarehouse(event){
    this.warehouseId = event.value.id;
    //ambil data stok
    this.getStock();
  }
  checkStock(event:any,index:any){
    let thisval = event.target.value;
    let stock = this.otherExpencesDetailList[index].stock;
    if(parseInt(thisval)>parseInt(stock)){
      let message = 'Pengeluaran tidak boleh melebihi stock';
      event.target.value = 0;
      this.showAlert(message);
    }
  }
  async getStock()
  {
    let options = {
      "warehouse_id": this.warehouseId,
      "stock_date":this.todayDate,
      "token": this.token
    };
    this.posService.getStockByWarehouse(options).subscribe((response) => {
      this.stock = [];
      if (response.status.code == 200) {
        this.stock = response.results.stock;
        //kalau list produknya udah ada yg diisi, cek lagi stoknya dan yg input lebih dari stock jadiin 0
        this.otherExpencesDetailList.find(x=>x.product_id)
        if(this.otherExpencesDetailList.length >0){
          let message = '';
          for(let i=0;i<this.otherExpencesDetailList.length;i++){
            let checkStock:any = this.stock.find(x => x.product_id === this.otherExpencesDetailList[i].product_id);
            let stock:number=0;
            if (checkStock) {
              stock = checkStock.stock;
            }
            this.otherExpencesDetailList[i].stock = stock;
            let htmlIdQtyReceive: any = 'qtyReceive_' + i;
            let qtyReceive=(<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;
            if(parseInt(qtyReceive)>stock){
              message += this.otherExpencesDetailList[i].product_id+',';
              (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value='0';
            }
          }
          if(message!=''){
            this.showAlert('Pengeluaran product '+message+' tidak boleh melebihi stok');
          }
        }
      }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Stock! Silahkan refresh!' }).then(t => t.present());
    }); 
  }
  async showAlert(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }
}
