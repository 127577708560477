import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { PromotionsService } from '../promotions.service';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import { PromotionDetailComponent } from '../../../component/promotion-detail/promotion-detail.component';
import * as XLSX from 'xlsx';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.page.html',
  styleUrls: ['./promotion-detail.page.scss'],
})
export class PromotionDetailPage implements OnInit {

  promotionId: number;
  token: any;
  userProfile: UserProfile = new UserProfile();
  promotionData: any;
  validOn:any='';
  roleAccess = new RoleAccess();
  statusName:any='';
  counterName:any='';
  warehouseName:any='';
  promotionName:any='';
  earlyPeriod:any='';
  endPeriod:any='';
  activeStatus:any='';
  promotionCashierType:any='';
  promotionTypeName='';
  promoBasedOn:number=0;
  promoBasedOnValue:any='';
  basedOnList : any[]=['Total Belanja','Total Belanja per Produk','Product'];
  product:any='';
  productTypelist : any[]=['All Products','Selected Product'];
  productRequiredData: any[] =[];
  qtyTypeList : any[]=['Per Produk','Gabungan'];
  detailData:any[]=[];
  freeItems:any[]=[];
  approvalStatus:number=0;
  discountTypeList: any[]=['Nominal','Persentase','Free Item'];
  discountType:number=0;
  priceTypeList : any[]=['Potongan','Harga Khusus','Persentase'];
  priceType:number=0;
  priceTypeValue:any='';
  productValue : number = 0;
  listProductBertingkat : any = '';
  showPromoBase:boolean=false;
  showProduct:boolean=false;
  showJenisDiskon:boolean=false;
  showDetailKontribusi:boolean=true;

  mstProductList: any[];
  productGimmickList:any[];
  stockData: any[] = [];
  reportPromo:any[]=[];
  isApproval:boolean=false;
  idApproval:number=0;
  level:number=0;
  
  //show hide di tabel
  //Min. Total Belanja	Product Scan	Min. Beli	Max. Beli	Product Hadiah	Diskon	
  //Diskon (%)	Harga	Qty Hadiah	Kelipatan	Max Diskon	Max Hadiah
  
  showMinimumTransaction:boolean=false;
  showProductScan:boolean=false;
  showMinBuy:boolean=false;
  showMaxBuy:boolean=false;
  showProductHadiah:boolean=false;
  showDiskon:boolean=false;
  showDiskonPersen:boolean=false;
  showHarga:boolean=false;
  showQtyHadiah:boolean=false;
  showKelipatan:boolean=false;
  showMaxDiscount:boolean=false;
  showMaxHadiah:boolean=false;
  showProductBertingkat:boolean=false;
  showAppliesTo:boolean=false;
  showQtyType:boolean=false;
  promoStatus:number=0;
  userId :number=0;
  waitingApprovalName:any='';
  history:any[]=[]

  constructor(
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private promotionService: PromotionsService,
    private toastCtrl: ToastController,
    private storage: Storage,
    private navCtrl: NavController,
    private alertController: AlertController,    
    private modalController: ModalController,
    private userData: UserData,
    private router: Router) { 
    
  }

  ngOnInit() {

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userId = this.userProfile.id;
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  
  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.promotionId = parseInt(AES.decrypt(snapshot.i, myKey).toString(utf8));

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.showMinimumTransaction=false;
        this.showProductScan=false;
        this.showMinBuy=false;
        this.showMaxBuy=false;
        this.showProductHadiah=false;
        this.showDiskon=false;
        this.showDiskonPersen=false;
        this.showHarga=false;
        this.showQtyHadiah=false;
        this.showKelipatan=false;
        this.showMaxDiscount=false;
        this.showMaxHadiah=false;
        this.showProductBertingkat=false;
        this.showAppliesTo=false;
        this.showQtyType =false;
        let options = {
          "token": this.token,
          "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
        };
        this.promotionService.getPromotionDetailforEdit(this.promotionId, options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.validOn='';
          this.promotionData = response.results.promotion_data[0];
          let approval= response.results.approval;
          if(approval.length>0){
            this.isApproval=false;
            this.waitingApprovalName+=' (';
            for(let i=0;i<approval.length;i++){
              this.waitingApprovalName+=approval[i].name+',';
              if(approval[i].username==this.userId){
                this.isApproval=true;
                this.idApproval=approval[i].id;
                this.level=approval[i].level;
              }
            }
            this.waitingApprovalName = this.waitingApprovalName.replace(/(^,)|(,$)/g, "");
            this.waitingApprovalName+=')';
          }
          this.approvalStatus =parseInt(this.promotionData.approval_status);
          if(this.promotionData.valid_on_su === '1') {this.validOn+='Minggu,'; };
          if(this.promotionData.valid_on_mo === '1') { this.validOn+='Senin,'; };
          if(this.promotionData.valid_on_tu === '1') { this.validOn+= 'Selasa,'; };
          if(this.promotionData.valid_on_we === '1') { this.validOn+= 'Rabu,'; };
          if(this.promotionData.valid_on_th === '1') { this.validOn+= 'Kamis,'; };
          if(this.promotionData.valid_on_fr === '1') { this.validOn+= 'Jumat,'; };
          if(this.promotionData.valid_on_sa === '1') { this.validOn+= 'Sabtu,'; };
          let active_status_name=parseInt(this.promotionData.status)==1?'Active':'Not Active';
          
          if(this.approvalStatus  == 1){
            this.statusName ='New';
          }else if(this.approvalStatus  == 2){
            this.statusName ='Waiting '+this.waitingApprovalName;
          }
          else  if(this.approvalStatus  == 3){
            this.statusName ='Approved';
          }
          else if(this.approvalStatus  == 4){
            this.statusName ='Reject';
          }
          this.counterName = this.promotionData.counter_name;
          this.warehouseName  =this.promotionData.warehouse_name;
          this.promotionName = this.promotionData.promotion_name;
          this.earlyPeriod = this.promotionData.early_period;
          this.endPeriod = this.promotionData.end_period;
          this.promotionCashierType=parseInt(this.promotionData.promotion_cashier_type)==1?'Reguler':'Reseller';
          this.activeStatus = active_status_name;
          this.promotionTypeName = this.promotionData.promotion_type_name;
          this.promoBasedOn =parseInt(this.promotionData.promo_based_on);
          this.promoBasedOnValue =this.basedOnList[ this.promoBasedOn-1];
          this.product = parseInt(this.promotionData.product)-1;
          this.productRequiredData = response.results.product_required_data;
          this.detailData = response.results.promotion_details_data;
          this.priceType = parseInt(this.promotionData.price_type);
          this.priceTypeValue =this.priceTypeList[this.priceType-1];;
          this.productValue = this.productTypelist[this.product];
          this.stockData = response.results.stock_data;
          this.listProductBertingkat=this.promotionData.product_list;
          this.reportPromo=response.results.report_promo;
          this.promoStatus = this.promotionData.status;
          this.history =response.results.history;
          if(this.listProductBertingkat!=null && this.listProductBertingkat!=undefined){
            this.listProductBertingkat= this.listProductBertingkat.replace(";", ",");
          }
          this.productGimmickList=(response.results.product_gimmick_data);
          this.mstProductList=(response.results.product_data);
          if(this.promotionData.discount_type!=null){
            this.discountType = parseInt(this.promotionData.discount_type);
          }
          if(this.promotionTypeName=='NOMINAL'){
            this.showQtyType=true;
            this.showPromoBase=true;
            this.showDiskon =true;
            this.showKelipatan=true;
            this.showMaxDiscount=true;
            if(this.promoBasedOn==1){
              this.showQtyType=false;
            }
            if(this.promoBasedOn!=3){
              this.showMinimumTransaction =true;
            }
            else{
              this.showMinBuy=true;
              this.showMaxBuy=true;
              this.showKelipatan=true;
              this.showProductScan=true;
              this.showAppliesTo=true;
            }
            if(this.promoBasedOn==2){
              this.showProduct=true;
              if(this.product==1){
                this.showProductScan=true;
              }
              else if(this.product<=0){
                this.showQtyType=false;
              }
            }
            else if(this.promoBasedOn==3){
              this.showProductScan=true;
            }
          }
          else if(this.promotionTypeName=='PERSENTASE'){
            if(this.promoBasedOn==1){
              this.showJenisDiskon = false;
            }
            else{
              if(this.product==1){
                this.showJenisDiskon = false;
              }
            }
            if(this.product==1 && this.promoBasedOn==2){
              this.showQtyType=true;
            }
            this.showPromoBase=true;
            if(this.promoBasedOn!=3){
              this.showMinimumTransaction =true;
            }
            else{
              this.showMinBuy=true;
              this.showMaxBuy=true;
              this.showKelipatan=true;
              this.showProductScan=true;
              this.showAppliesTo=true;
            }
            this.showMaxDiscount=true;
            this.showDiskonPersen =true;
            if(this.promoBasedOn==2){
              this.showProduct=true;
              if(this.product==1){
                this.showProductScan=true;
              }
            }
          }
          else if(this.promotionTypeName=='FREE ITEM (ITEM)'){
            this.showDetailKontribusi=false;
            this.showProduct=true;
            this.showMinBuy =true;
            this.showProductHadiah =true;
            this.showQtyHadiah =true;
            this.showKelipatan =true;
            this.showMaxHadiah =true;
            if(this.product==1){
              this.showProductScan=true;
            }
            this.showQtyType=true;
          }
          else if(this.promotionTypeName=='FREE ITEM (NOMINAL)'){
            this.showDetailKontribusi=false;
            this.showPromoBase=true;
            this.showMinimumTransaction=true;
            this.showProductHadiah =true;
            this.showQtyHadiah =true;
            this.showKelipatan =true;
            this.showMaxHadiah =true;
            if(this.promoBasedOn==2){
              this.showProduct=true;
              if(this.product==1){
                this.showProductScan=true;
                this.showQtyType=true;
              }
            }
          }
          else if(this.promotionTypeName=='PROMO BERTINGKAT'){
            this.showPromoBase=true;
            this.priceTypeValue = this.discountTypeList[this.discountType-1];
            if(this.promoBasedOn==1 || this.promoBasedOn==2){
              this.showMinimumTransaction=true;
              if(this.discountType==1){
                this.showDiskon =true;
              }
              else if(this.discountType==2){
                this.showDiskonPersen=true;
                this.showMaxDiscount=true;
              }
              else if(this.discountType==3){
                this.showProductHadiah=true;
                this.showQtyHadiah=true;
                this.showMaxHadiah=true;
              }
              if( this.promoBasedOn==2 && this.product==1){
                this.showProduct=true;
                this.showProductBertingkat =true;
              }
              else if( this.promoBasedOn==2 && this.product==0){
                this.showProduct=true;
              }
            }
            else if(this.promoBasedOn==3){
              this.showMinBuy=true;
              this.showProduct=true;
              if(this.product==1){
                this.showProductBertingkat =true;
              }
              if(this.discountType==1){
                this.showDiskon =true;
              }
              else if(this.discountType==2){
                this.showDiskonPersen=true;
                this.showMaxDiscount=true;
              }
              else if(this.discountType==3){
                this.showProductHadiah=true;
                this.showQtyHadiah=true;
                this.showMaxHadiah=true;
              }
            }
          }
          else if(this.promotionTypeName=='FREE ITEM AND NOMINAL'){
            this.showProductHadiah=true;
            this.showMaxHadiah=true;
            this.showQtyHadiah=true;
            this.showKelipatan=true;
            if(this.priceType==1){
              this.showMinimumTransaction=true;
              this.showDiskon=true;
              this.showPromoBase=true;
            }
            else if(this.priceType==2){
              this.showHarga=true;
            }
            else if(this.priceType==3){
              this.showMinimumTransaction=true;
              this.showDiskonPersen=true;
              this.showPromoBase=true;
            }
            if( this.promoBasedOn==2){
              this.showProduct=true;
              if(this.product==1){
                this.showProductScan=true;
                this.showQtyType=true;
              }
            }
            else if(this.promoBasedOn==3){
              this.showMinimumTransaction=false;
              this.showMinBuy=true;
              this.showProductScan=true;
              this.showQtyType=true;
            }
          }
          //promo based on : 1 total belanja,2 total belanja per produk, 3 produk
          //price type :0:-, 1: potongan, 2: harga khusus
        //product:1-1:all product,2-1:selected product,
        //diskon type (khusus diskon bertingkat). 1:Nominal, 2 : Persentase 3: free item
          this.freeItems = response.results.promotion_free_items;
          let free_item_list ='';
          for(let i = 0; i < this.detailData.length; i++) {
            this.detailData[i].minimum_transaction=parseInt(this.detailData[i].minimum_transaction);
            this.detailData[i].is_multiple = this.detailData[i].multiple_flag==1?'Ya':'Tidak';
            this.detailData[i].free_item_array =this.freeItems.filter(x => parseInt(x.promotion_detail_id) === parseInt(this.detailData[i].id));
            free_item_list='';
            if(this.detailData[i].free_item_array.length>0){
              for(let j=0;j<this.detailData[i].free_item_array.length;j++){
                free_item_list +=this.detailData[i].free_item_array[j].product_id+',';
              }
            }
            free_item_list = free_item_list.replace(/,\s*$/, "");
            this.detailData[i].free_item_list = free_item_list;
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }
  
  async openPromotionDetail(index: any, action: any) {
    const modal = await this.modalController.create({
      component: PromotionDetailComponent,
      componentProps: {
        'mstProductList': this.mstProductList,
        'mstProductGimmickList': this.productGimmickList,
        'productScan': this.detailData[index].product_id ? this.detailData[index].product_id.replace(",", ";") : null,
        'productFreeItem':this.detailData[index].free_item_list ? this.detailData[index].free_item_list.replace(",", ";") : null,
        'listProductScan':this.listProductBertingkat ? this.listProductBertingkat.replace(",", ";") :null,
        'productRequired':this.productRequiredData[index] ? this.productRequiredData[index].product_id:null,
        'stockData':this.stockData,
        'action': action,
        'viewOnly':"Y"
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      console.log(modelData);
    });

    return await modal.present();
  }
  async showConfirmDeactivate(){

    const alert = await this.alertController.create({
      header: 'Approval Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk menonaktifkan promo ini?',
      buttons: [
        {
          text: 'Tidak',
          handler: () => {}
        },
        {
          text: 'Ya',
          handler: () => {
            this.showInputApprovalReason(0,'Menonaktifkan');
          }
        }
      ]
    });
    await alert.present();
  }
  async showConfirmActivate(){

    const alert = await this.alertController.create({
      header: 'Approval Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk mengaktifkan promo ini?',
      buttons: [
        {
          text: 'Tidak',
          handler: () => {}
        },
        {
          text: 'Ya',
          handler: () => {
            this.showInputApprovalReason(5,'Mengaktifkan');
          }
        }
      ]
    });
    await alert.present();
  }
  async showConfirmSend(action:number) {
    let actionName = '';
    if(action==3){
      actionName='Approve';
    }
    else if(action==4){
      actionName='Reject';
    }
    else if(action==5){
      actionName='Mengaktifkan';
    }
    const alert = await this.alertController.create({
      header: 'Approval Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk '+actionName+' data ini?',
      buttons: [
        {
          text: 'Tidak',
          handler: () => {}
        },
        {
          text: 'Ya',
          handler: () => {
            this.showInputApprovalReason(action,actionName);
          }
        }
      ]
    });

    await alert.present();
  }

  async showInputApprovalReason(action: number,actionName:string) {
    const alert = await this.alertController.create({
      header: 'Confirmation',
      subHeader: 'Keterangan',
      message: 'Silahkan masukkan alasan '+actionName,
      inputs: [
        {
          name: 'approvalRemark',
          placeholder: 'Keterangan',
          type: 'text'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: (input: any) => {
            let remark = input.approvalRemark ? input.approvalRemark : '';
            if(action==0){
              this.deactivate(remark);
            }
            else if(action==5){
              this.activate(remark);
            }
            else{
              this.approval(action, actionName, remark);
            }
          }
        }
      ]
    });
    
    await alert.present();
  }
  
  activate(remark:string) {
    let options = {
      "token": this.token,
      "id": this.promotionId,
      "updated_by": this.userProfile.username,
      "remark":remark
    };
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.promotionService.activate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.toastCtrl.create({ duration: 2000, message: 'Mengaktifkan Promo Berhasil' }).then(t => t.present());
          this.navCtrl.navigateForward(['/promotions']);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  deactivate(remark:string) {
    let options = {
      "token": this.token,
      "id": this.promotionId,
      "updated_by": this.userProfile.username,
      "remark":remark
    };
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.promotionService.deactivate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.toastCtrl.create({ duration: 2000, message: 'Menonaktifkan Promo Berhasil' }).then(t => t.present());
          this.navCtrl.navigateForward(['/promotions']);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  approval(action:number,actionName:string,remark:string) {
    let options = {
      "token": this.token,
      "id": this.promotionId,
      "updated_by": this.userProfile.username,
      "approval_status":action,
      "remark":remark,
      "level":this.level,
      'approval_id':this.idApproval
    };
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.promotionService.sendApproval(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.toastCtrl.create({ duration: 2000, message: actionName+' Berhasil' }).then(t => t.present());    
          this.navCtrl.navigateForward(['/promotion-approval']);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  } 
  
  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table-report-promo');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    for(let i=0;i<6;i++){
        ws['!cols'].push({ width: 20 });
    }

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb,  'Report Promo '+this.promotionName.replace(' ','_')+'.xlsx');
  }
}