import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { OtherExpencesService } from '../other-expences.service';
import { OtherExpencesBundle } from '../../../models/other-expences-bundle.model';
import { FindProductComponent } from '../../../component/find-product/find-product.component';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { PosService } from '../../pos/pos.service';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-other-expences-create',
  templateUrl: './other-expences-create.page.html',
  styleUrls: ['./other-expences-create.page.scss'],
})
export class OtherExpencesCreatePage implements OnInit {
  @ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;
  @ViewChild('warehouseComponent', { static: false }) warehouseComponent: IonicSelectableComponent;

  token: any;
  productSearch: Product[];
  productList: Product[];
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  warehouseData: any[] = [];
  purposeData: any[] = [];
  otherExpencesDetailList: any[] = [];

  formOtherExpencesCreate: FormGroup;
  otherExpencesId: any;
  
  roleAccess = new RoleAccess();
  warehouseId :any='';
  counterId :any='';
  selectedWarehouses:any[];
  warehouseListShow:any[]=[];
  stock:Array<any>;
  todayDate:any='';

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private modalController: ModalController,
    private expencesService: OtherExpencesService,
    private router: Router,
    private posService:PosService
  ) { }

  ngOnInit() {
    this.buildFormOtherExpences();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{ 
          this.getOtherExpencesForCreate();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getOtherExpencesForCreate() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.expencesService.getOtherExpencesforCreate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.productList = response.results.product_data;
          this.counterList = response.results.counter_data;
          this.warehouseData = response.results.warehouse_data;
          this.warehouseListShow = this.warehouseData;
          this.purposeData = response.results.purpose_data;

          this.buildFormOtherExpences();
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  buildFormOtherExpences() {
    let newDate = new Date();
    let convertDate = this.utilService.convertDate(newDate);
    this.todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formOtherExpencesCreate = this.fb.group({
      counterId: [this.counterId !='' ? parseInt(this.counterId) : null, Validators.required],
      warehouseId: [this.warehouseId != '' ? parseInt(this.warehouseId) : null, Validators.required],
      purposeId: [this.purposeData.length > 0 ? parseInt(this.purposeData[0].id) : null, Validators.required],
      docDate: [this.todayDate, Validators.required],
      description: [null, Validators.required]
    });
  }

  inputBundle() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formOtherExpences = this.formOtherExpencesCreate.value;
      let docDateConvert = this.utilService.convertDate(formOtherExpences.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      let arrProduct: any = [];
      let arrQty: any = [];
      let arrStock:any = [];
      let arrUnitType: any = [];
      let arrDesc: any = [];

      for(let x = 0; x < this.otherExpencesDetailList.length; x++) {
        let htmlIdQtyReceive: any = 'qtyReceive_' + x;
        let htmlIdDesc: any = 'description_' + x;
        let qtyReceive: any = (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;
        let description: any = (<HTMLInputElement>document.getElementById(htmlIdDesc)).value;

        arrProduct[x] = this.otherExpencesDetailList[x].product_id;
        arrQty[x] = parseInt(qtyReceive);
        arrStock[x] = parseInt(this.otherExpencesDetailList[x].stock);
        arrUnitType[x] = 'PCS';
        arrDesc[x] = description;
      }

      const otherExpencesBundle = new OtherExpencesBundle();
      otherExpencesBundle.otherExpences.counter_id = this.counterId;
      otherExpencesBundle.otherExpences.warehouse_id = this.warehouseId;
      otherExpencesBundle.otherExpences.purpose_id = formOtherExpences.purposeId;
      otherExpencesBundle.otherExpences.doc_date = documentDate;
      otherExpencesBundle.otherExpences.desc = formOtherExpences.description;
      otherExpencesBundle.otherExpences.status = '0';
      otherExpencesBundle.otherExpences.created_by = this.userProfile.username;

      otherExpencesBundle.otherExpencesDetail.product_id = arrProduct;
      otherExpencesBundle.otherExpencesDetail.qty = arrQty;
      otherExpencesBundle.otherExpencesDetail.stock = arrStock;
      otherExpencesBundle.otherExpencesDetail.unit = arrUnitType;
      otherExpencesBundle.otherExpencesDetail.description = arrDesc;

      this.expencesService.addOtherExpencesBundle(otherExpencesBundle)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/other-expences']);;
          }
        }
      ]
    });

    await alert.present();
  }

  addDetail() {
    if(this.warehouseId==''){
      alert('mohon pilih warehouse terlebih dahulu');
    }
    else{
      let length = this.otherExpencesDetailList.length;
      this.otherExpencesDetailList.push({
        'id' : length + 1,
        'product_id' : null,
        'product_name' : null,
        'unit': null,
        'stock':0
      });
    }
  }

  async findProduct(index: any) {
    const modal = await this.modalController.create({
      component: FindProductComponent,
      componentProps: {
        'productList': this.productList
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if(data) {
        let check = this.otherExpencesDetailList.find(x=>x.product_id==data.id);
        if(check){
          this.showAlert('produk '+data.id+' sudah ada di list');
        }
        else{
          let findProduct = this.otherExpencesDetailList.indexOf(data);
          if(findProduct === -1) {
            this.otherExpencesDetailList[index].product_id = data.id;
            this.otherExpencesDetailList[index].product_name = data.product_name;
            let checkStock:any = this.stock.find(x => x.product_id === data.id);
            let stock:number=0;
            if (checkStock) {
              stock = checkStock.stock;
            }
            this.otherExpencesDetailList[index].stock = stock;
          }
        }
      }
    });

    return await modal.present();
  }
  checkStock(event:any,index:any){
    let thisval = event.target.value;
    let stock = this.otherExpencesDetailList[index].stock;
    if(parseInt(thisval)>parseInt(stock)){
      let message = 'Pengeluaran tidak boleh melebihi stock';
      event.target.value = 0;
      this.showAlert(message);
    }
  }
  async showAlert(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }
  deleteProduct(index: any) {
    this.otherExpencesDetailList.splice(index, 1);
  }  

  selectWarehouse(event){
    this.warehouseId = event.value.id;
  }
    
  async getStock()
  {
    let options = {
      "warehouse_id": this.warehouseId,
      "stock_date":this.todayDate,
      "token": this.token
    };
    this.posService.getStockByWarehouse(options).subscribe((response) => {
      this.stock = [];
      if (response.status.code == 200) {
        this.stock = response.results.stock;
        //kalau list produknya udah ada yg diisi, cek lagi stoknya dan yg input lebih dari stock jadiin 0
        this.otherExpencesDetailList.find(x=>x.product_id)
        if(this.otherExpencesDetailList.length >0){
          let message = '';
          for(let i=0;i<this.otherExpencesDetailList.length;i++){
            let checkStock:any = this.stock.find(x => x.product_id === this.otherExpencesDetailList[i].product_id);
            let stock:number=0;
            if (checkStock) {
              stock = checkStock.stock;
            }
            this.otherExpencesDetailList[i].stock = stock;
            let htmlIdQtyReceive: any = 'qtyReceive_' + i;
            let qtyReceive=(<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;
            if(parseInt(qtyReceive)>stock){
              message += this.otherExpencesDetailList[i].product_id+',';
              (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value='0';
            }
          }
          if(message!=''){
            this.showAlert('Pengeluaran product '+message+' tidak boleh melebihi stok');
          }
        }
      }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Stock! Silahkan refresh!' }).then(t => t.present());
    }); 
  }
  selectCounter(event){
    this.counterId = event.value.id;
    this.warehouseId ='';
    this.selectedWarehouses=[];
    let filterWarehouse = this.warehouseData.filter(x => parseInt(x.counter_id) === parseInt(this.counterId));
    this.warehouseListShow = filterWarehouse;
  }
  confirmCounter() {
    this.warehouseId ='';
    this.counterComponent.confirm();
    this.counterComponent.close();
    this.selectedWarehouses=[];
    this.counterId = this.counterComponent.value.id;
    let filterWarehouse = this.warehouseData.filter(x => parseInt(x.counter_id) === parseInt(this.counterId));
    this.warehouseListShow = filterWarehouse;
    this.getStock();
  }
  
  confirmWarehouse(){
    this.warehouseComponent.confirm();
    this.warehouseComponent.close();
    this.warehouseId = this.warehouseComponent.value.id;
    //ambil data stok
    this.getStock();
  }
}
